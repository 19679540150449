import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import './Rodape.css';
import logo from '../../images/delta.svg';
import React from 'react';
import logostang from '../../images/logo_stang.png';
import { API_URL, axiosconfig } from '../../core';
import axios from 'axios';
import { FaArrowRight, FaPhoneAlt, FaFacebookSquare, FaInstagramSquare, FaLinkedin, FaRegEnvelope } from 'react-icons/fa';
import ReCAPTCHA from "react-google-recaptcha";
import Animate from 'react-smooth';

const recaptchaRef = React.createRef();

class Rodape extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            phone: '',
            email: '',
            dept: 'Atendimento em Geral',
            message: '',
            sent: false,
            err: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        const recaptchaValue = recaptchaRef.current.getValue();
        const data = new FormData()
        data.append('name', this.state.name);
        data.append('phone', this.state.phone);
        data.append('email', this.state.email);    
        data.append('dept', this.state.dept);    
        data.append('message', this.state.message);
        data.append('grecaptcha', recaptchaValue);
        axios.post(`${API_URL}/api/contact`, data, axiosconfig)
            .then(res => {
                if (res.status === 200) {
                    this.setState({ sent: true, err: false })
                }
            }).catch(err =>
                this.setState({ err: true })
            )
        return false;
    }
    render() {
        return (
            <Animate to="1" from="0" attributeName="opacity">
                <div className="containerrodape" >
                    <div className="container">
                        <Container style={{ paddingTop: '50px' }}>
                            <Row style={{ color: 'white' }}>
                                <Col sm={3}>
                                    <a href='/promocoes' className="links"><b>Promoções</b></a>
                                    <ul style={{ listStyle: 'none', marginTop: '10px' }}>
                                        {this.props.promos.map((item, i) =>
                                            <li key={i}>
                                                <a href={`/promocoes#${item.slug}`} className="sublinks">{item.title}</a>
                                            </li>
                                        )}
                                    </ul>
                                </Col>
                                <Col sm={3}>
                                    <a href='/' className="links"><b>Produtos & Serviços</b></a>
                                    <ul style={{ listStyle: 'none', marginTop: '10px' }}>
                                        {this.props.services.map((item, i) =>
                                            <li key={i}>
                                                <a href={`/servicos/${item.slug}`} className="sublinks">{item.title}</a>
                                            </li>
                                        )}
                                    </ul>
                                </Col>

                                <Col sm={2}>
                                    <a href='/sobre' className="links">Sobre Nós</a>
                                </Col>

                                <Col sm={2} style={{ zIndex: 3 }}>
                                    <a href='#/contato' className="links">Contato</a>
                                </Col>

                                <Col sm={2} style={{ zIndex: 3 }}>
                                    <a href='/blog' className="links">Blog</a>
                                </Col>
                            </Row>
                        </Container>
                        <div className="separador"></div>
                        <Container style={{ scrollMargin: '100px' }} id="contato">
                            <Row>
                                <Col sm={6}>
                                    <h1 style={{ color: '#00D3E5', marginTop: '30px' }}>Contato Delta</h1>
                                    {!this.state.sent ?
                                        <Form style={{ marginBottom: '30px' }} onSubmit={this.handleSubmit}>
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="formGridEmail">
                                                    <Form.Control type="text" onChange={(e) => this.setState({ name: e.target.value })} placeholder="* Nome" className="input" required />
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="formGridPassword">
                                                    <Form.Control type="text" onChange={(e) => this.setState({ phone: e.target.value })} placeholder="* Telefone" className="input" required />
                                                </Form.Group>
                                            </Form.Row>

                                            <Form.Group controlId="formGridAddress1">
                                                <Form.Control type="email" onChange={(e) => this.setState({ email: e.target.value })} placeholder="* E-mail" className="input" required />
                                            </Form.Group>

                                            <Form.Group controlId="formGridState">
                                                <Form.Control as="select" onChange={(e) => this.setState({ dept: e.target.value })} defaultValue="Atendimento em Geral" className="input" required>
                                                    <option>Atendimento em Geral</option>
                                                    <option>Compras</option>
                                                    <option>Direção</option>
                                                    <option>Marketing</option>
                                                    <option>Recursos Humanos</option>
                                                    <option>SAC</option>
                                                </Form.Control>
                                            </Form.Group>

                                            <Form.Group controlId="formGridAddress2">
                                                <Form.Control as="textarea" onChange={(e) => this.setState({ message: e.target.value })} placeholder="* Mensagem" className="input" required rows="4" />
                                            </Form.Group>
                                            <Form.Group>
                                                <ReCAPTCHA
                                                    ref={recaptchaRef}
                                                    sitekey="6LcZPMwgAAAAAFXdTzmwYouVB4rxFP_0G4rvIUWz"
                                                />
                                            </Form.Group>

                                            <Button variant="primary" type="submit" style={{ backgroundColor: '#00D3E5' }}>
                                                ENVIAR <FaArrowRight />
                                            </Button>
                                            <span style={{ color: 'white' }}> *Campos com preenchimento obrigatório.</span>
                                            {this.state.sent ? <span>Enviado!</span> : null}
                                        </Form> :
                                        <div style={{ textAlign: 'center' }}><h3 style={{ color: "green" }}>Obrigado pelo seu contato!</h3></div>
                                    }
                                    {this.state.err ? <div style={{ textAlign: 'center' }}><h4 style={{ color: "red" }}>Verifique a sua conexão e tente novamente</h4></div> : null}
                                </Col>
                                <Col sm={6}>
                                    <div className="contatorodape" style={{ textAlign: 'left', paddingTop: '5rem', paddingLeft: '4rem', zIndex: 3 }}>
                                        <img src={logo} alt="logo delta" className="" style={{ maxWidth: '50%' }} />
                                        <div style={{ paddingTop: '2rem' }}>
                                            <p style={{ lineHeight: '0.5' }}> <a href="https://www.facebook.com/postodeltaoficial" target="_blank" rel='noopener' style={{color: 'white'}}><FaFacebookSquare /></a> <a style={{color: 'white'}} href="https://www.linkedin.com/company/rede-delta/" target="_blank"><FaLinkedin /></a> <a style={{color: 'white'}} href="https://www.instagram.com/postodelta/" target="_blank"><FaInstagramSquare /></a> Conecte-se com o Delta</p>
                                            <p style={{ lineHeight: '0.5' }}> <FaPhoneAlt /> (46) 3151-1800</p>
                                            <p style={{ lineHeight: '0.5' }}> <FaRegEnvelope /> sac@rededelta.com.br</p>
                                        </div>
                                        <a href="https://www.stangdistribuidora.com.br/" target="blank" style={{ paddingRight: '0.2rem', color: 'white' }}>
                                            <img src={logostang} alt="Stang Distribuidora de Petróleo" style={{ maxWidth: '30%', paddingTop: '1rem' }} />
                                         </a>
                                    </div>
                                </Col>
                            </Row>
                            <div style={{ display: 'flex', textAlign: 'left', position: 'relative', zIndex: 3, fontSize: '0.8rem' }}>
                                <div style={{ textAlign: 'left' }} >
                                    <span style={{ color: 'white' }}> Copyright © 2021, Todos os direitos reservados Rede Delta</span>
                                    <p style={{ color: 'white' }}> Desenvolvido por
                                    <a href="https://camaleonmkt.com.br/" target="blank" style={{ paddingRight: '0.2rem', color: 'white' }}> <b>Camaleon MKT</b></a>
                                    e
                                    <a href="https://www.techraptors.com.br/" target="blank" style={{ color: 'white' }}> <b>TECHRAPTORS</b></a>
                                    </p>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
            </Animate>
        );
    }
}
export default Rodape;
